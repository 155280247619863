.navbar {
    display: flex;
}
ul {
    list-style-type: none;
}

li {
    padding: 8px;
    color: rgb(14, 50, 100);
}

td,
th {
    padding: 8px;
    border: 3px solid rgb(237, 237, 237);
}
