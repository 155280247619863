.btn {
    font-weight: 600;
    background-color: rgb(254, 219, 49);
    color: rgb(14, 50, 100);
    border-radius: 4px;
    border-style: none;
    height: 30px;
}
.not-active-btn {
    font-weight: 600;
    background-color: rgba(254, 220, 49, 0.432);
    color: rgb(14, 50, 100);
    border-radius: 4px;
    border-style: none;
    height: 30px;
}

.not-active-btn{
    font-weight: 600;
    background-color: rgba(254, 220, 49, 0.377);
    color: rgb(14, 50, 100);
    border-radius: 4px;
    border-style: none;
    height: 30px;
}


.preview {
    vertical-align: middle;
    height: 30px;
    padding-left: 16px;
    max-width: 30px;
}

.preview-form {
    vertical-align: middle;
    height: 30px;
    margin-left: 8px;
    margin-right: 16px;
}

.icon {
    height: 10px;
}

.iconBtn {
    background-color: rgb(254, 219, 49);
    color: rgb(14, 50, 100);
    border-radius: 4px;
    border-style: none;
    justify-content: center;
    align-content: center;
    align-self: center;
}

.tab {
    color: rgb(11, 101, 171);
}

.grid-container {
    display: grid;
    grid-template-columns: 20% 30% 20% 30%;
    grid-template-rows: auto auto auto;
}

.grid2-container {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
}

.grid-container-3-auto {
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr 1fr ;
    grid-template-rows: auto auto auto;
}

label,
.label {
    padding: 0 8px 8px 8px;
}
.margin {
    margin-bottom: 16px;
}

input {
    font-family: "Roboto";
    margin-bottom: 16px;
    border: 2px solid rgb(237, 237, 237);
    border-radius: 4px;
}
.error {
    border: 2px solid rgb(243, 46, 46);
}
.select {
    margin-bottom: 16px;
    border: 2px solid rgb(237, 237, 237);
    border-radius: 4px;
}

.error.select {
    border: 2px solid rgb(243, 46, 46);
}

fieldset {
    margin: 16px 0;
    padding: 12px;
    border: 3px solid rgb(237, 237, 237);
    border-radius: 4px;
}

table {
    margin: 16px 0;
    width: 100%;
}

.label {
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

ul {
    list-style-type: none;
}

li {
    padding: 8px;
}

td,
th {
    padding: 8px;
    border: 3px solid rgb(237, 237, 237);
}

.grid-container-50 {
    display: grid;
    grid-template-columns: 35% 15% 40% 10%;
    grid-template-rows: auto auto auto;
}

.popup-center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #ffffff;
    min-width: 640px;
}

.sms-verification {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #ffffff;
    max-width: 420px;
}

.client-verification {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #ffffff;
    max-width: 600px;
}

.ul-column {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.rejection {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: #ffffff;
}
.rejection-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #ffffff;
    min-width: 800px;
}

.rejection-comment {
    align-items: center;
    display: grid;
    grid-template-columns: 100%;

}

.label-input input {
    margin-left: 8px;
}

.lb-error {
    font-weight: bold;
    color: rgb(243, 46, 46);
}

.lb-success {
    font-weight: bold;
    color: rgb(254, 219, 49);
}
.bold-a {
    text-decoration: underline;
    color: black;
}